
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Action} from "vuex-class";
import {StoreAction} from "@/types";
import {formatErrors, passwordStrength} from "@/helpers/commons";

@Component({
    name: "ResetPassword"
})
export default class ResetPassword extends Vue {
    loading = true;
    errors: any = {};
    globalErrors: any = {};
    password = "";
    confirmPassword = "";
    passwordStrength = passwordStrength;

    @Action('auth/resetPassword') resetPassword!: StoreAction;

    get token() {
        return this.$route.query.token;
    }

    validate() {
        if (!this.password) {
            this.errors.password = {id: 'Validation.Any.Base'};
        }
        if (!this.confirmPassword) {
            this.errors.confirmPassword = {id: 'Validation.Any.Base'};
        }
        if (this.password !== this.confirmPassword) {
            this.errors.confirmPassword = {id: 'Validation.Password.MisMatch'};
        }
        return !Object.keys(this.errors).length;
    }

    async sendReset() {
        this.loading = true;
        this.errors = {};
        this.globalErrors = [];
        try {
            if (this.validate()) {
                await this.resetPassword({
                    resetToken: this.token,
                    password: this.password
                });
                await this.$router.push("/");
            }
        } catch (e) {
            console.log(e);
            this.errors = formatErrors(e).errors;
            this.globalErrors = formatErrors(e)._global;
        }
        this.loading = false;
    }
}
